type Size = "xs" | "sm" | "md" | "lg" | "xl";
type Theme = "light" | "dark";

export interface LoadingIndicatorProps {
  size?: Size;
  theme?: Theme;
}

const dimensionsMap: Record<Size, string> = {
  xs: "h-4 w-4",
  sm: "h-9 w-9",
  md: "h-12 w-12",
  lg: "h-16 w-16",
  xl: "h-24 w-24"
};

const borderSizeMap: Record<Size, string> = {
  xs: "border-2",
  sm: "border-4",
  md: "border-6",
  lg: "border-6",
  xl: "border-8"
};

export function LoadingIndicator({
  size = "md",
  theme = "dark"
}: LoadingIndicatorProps) {
  const dimensions = dimensionsMap[size];
  const borderSize = borderSizeMap[size];
  const borderColor = theme === "dark" ? "border-black" : "border-white";
  const borderBackgroundColor =
    theme === "dark" ? "border-gray-500" : "border-gray-100";
  return (
    <div aria-hidden="true" className={`relative inline-block ${dimensions}`}>
      <div
        className={`absolute rounded-full border-solid ${borderBackgroundColor} opacity-25 ${dimensions} ${borderSize}`}
      />
      <div
        className={`absolute animate-spin rounded-full border-solid ${borderColor} border-t-transparent opacity-75 ${dimensions} ${borderSize}`}
      />
    </div>
  );
}
